<template>
  <div>
    <div v-if="isPDF" class="d-flex justify-center">
      <iframe
        :src="`https://docs.google.com/viewer?url=${pdfURL}&embedded=true`"
        height="500px"
        width="500px"
      />
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-2 gradient-primary"
            dark
            dense
            depressed
            @click="openLink(pdfURL)"
          >
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("e_test.open_file") }}</span>
      </v-tooltip>
    </div>
    <v-row v-else v-show="show" class="ma-5">
      <v-col cols="12" sm="3">
        <v-select
          v-model="chapter"
          :items="toc"
          item-text="label"
          item-disabled="inactive"
          item-value="href"
          label="Chapter"
          outlined
          dense
          hide-details
          @change="goToExcerpt"
        />
      </v-col>
      <v-col cols="12" style="position: relative">
        <div id="epub-render" />
        <v-btn id="prev" class="arrow" depressed icon @click="previousPage()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon id="next" class="arrow" depressed @click="nextPage()">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ePub from "epubjs";

export default {
  props: {
    url: String
  },
  data() {
    return {
      isPDF: false,
      pdfURL: "",
      show: false,
      book: {},
      rendition: {},
      chapter: "",
      toc: []
    };
  },
  watch: {
    url(v) {
      this.isPDF = this.extension(v).toLowerCase() === "pdf";
      if (this.isPDF) this.pdfURL = v;
      else this.loadEpub(v);
    }
  },
  mounted() {
    this.isPDF = this.extension(this.url).toLowerCase() === "pdf";
    if (this.isPDF) this.pdfURL = this.url;
    else this.loadEpub(this.url);
  },
  methods: {
    openLink: url => window.open(url, "_blank"),
    extension(str) {
      return str.substr(str.lastIndexOf(".") + 1);
    },
    loadEpub(e) {
      this.book = ePub(e);
      this.book.loaded.navigation.then(({ toc }) => {
        this.toc = toc;
      });
      this.book.ready.then(() => {
        this.show = true;
      });
      this.rendition = this.book.renderTo("epub-render", {
        height: "60vh",
        width: "98%"
      });
      this.rendition.display();
      document.getElementById("add");
    },
    parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    nextPage() {
      this.rendition.next();
    },
    previousPage() {
      this.rendition.prev();
    },
    goToExcerpt() {
      if (this.chapter.toLowerCase().indexOf("xhtml") > 0) {
        this.rendition.display(this.chapter);
      } else {
        this.rendition.display("epubcfi(" + this.chapter + ")");
        this.rendition.annotations.highlight("epubcfi(" + this.chapter + ")");
      }
    }
  }
};
</script>

<style scoped>
#prev {
  left: 0;
  position: absolute;
}
#next {
  right: 0;
  position: absolute;
}
.arrow:hover {
  color: gray !important;
}
.arrow {
  position: fixed;
  top: 50%;
  margin-top: -32px;
  font-size: 64px;
  color: gainsboro !important;
  font-family: arial, sans-serif;
  font-weight: bold;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-decoration: none;
}
</style>
